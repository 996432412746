import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../_actions';
import { compareUtil } from '../../_helpers';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import LocationModal from './LocationModal';
import EditLocationModal from './EditLocationModal';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

const headCells = [
    {id: 'Name', numeric: false, label: 'Name'},
    {id: 'CompanyName', numeric: false, label: 'Company Name'},
    {id: 'City', numeric: false, label: 'City'},
    {id: 'Address1', numeric: false, label: 'Address 1'},
    {id: 'Address2', numeric: false, label: 'Address 2'},
    {id: 'PostalZip', numeric: false, label: 'Postal/Zip Code'},
    {id: 'StateProv', numeric: false, label: 'Province/State'},
    {id: 'Country', numeric: false, label: 'Country'},
    {id: 'ContactName', numeric: false, label: 'Contact Name'},
    {id: 'ContactPhone', numeric: false, label: 'Contact Phone'},
    {id: 'ContactEmail', numeric: false, label: 'Contact Email'},
    {id: 'ContactInfo', numeric: false, label: 'Contact Info'},
    {id: 'Instructions', numeric: false, label: 'Instructions'},
];

const classes = {
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 500
    },
    tableHeaderStyle: {
		fontWeight: 'bold'
	},
    addLocationButton: {
        textTransform: 'none',
        paddingLeft: 3,
        paddingRight: 3,
        margin: 1,
        backgroundColor: '#0D9276',
        '&:hover': {
            backgroundColor: '#0D9276'
        },
    },
    locationListHeader: {
        fontSize: 20
    },
    locationListSubHeader: {
        float: 'right'
    },
    alert: {
        marginTop: 10
    },
    alertText: {
        fontWeight: 'bold'
    }
};

function LocationAddressBook() {
    // Next 3 are for opening/closing the modals used to add Locations 
    const [openLocationModal, setOpenLocationModal] = useState(false);
    const handleOpenLocationModal = () => setOpenLocationModal(true);
    const handleCloseLocationModal = () => setOpenLocationModal(false);
    // Next 4 are for editing Locations
	const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
	const handleOpenEditLocationModal = () => setOpenEditLocationModal(true);
	//const handleCloseEditLocationModal = () => setOpenEditLocationModal(false);
	const [currentLocation, setCurrentLocation] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.location.loading);
    const locationList = useSelector(state => state.location.locationList);

    useEffect(() => { 
        dispatch(locationActions.getLocationList(user.CompanyId));
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

    const openEditLocationForm = location => {
        handleOpenEditLocationModal();
        setCurrentLocation({...location});
	};

    const handleCloseEditLocationModal = (event, reason) => {
        if(reason && reason === "backdropClick") {
            return;
        }
        setOpenEditLocationModal(false);
    };

    return (
        <Container>
            <Typography sx={classes.locationListSubHeader}>Click on a  row to edit location</Typography>
            <Typography sx={classes.locationListHeader}>Current Locations: {!loading ? locationList.length : null}</Typography>
            {!loading ?
            <Paper>
                <Button onClick={handleOpenLocationModal} variant="contained" sx={classes.addLocationButton} startIcon={<AddIcon sx={{marginRight: -0.5}}/>}>Add</Button>
                <TableContainer sx={classes.tableStyle}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'left'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            sx={classes.tableHeaderStyle}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {locationList && locationList.length > 0 ?
                                compareUtil.stableSort(locationList, compareUtil.getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover sx={{ cursor: 'pointer' }} key={row.Id} onClick={() => openEditLocationForm(row)}>
                                        <TableCell align="left">{row.Name}</TableCell>
                                        <TableCell align="left">{row.CompanyName}</TableCell>
                                        <TableCell align="left">{row.City}</TableCell>
                                        <TableCell align="left">{row.Address1}</TableCell>
                                        <TableCell align="left">{row.Address2}</TableCell>
                                        <TableCell align="left">{row.PostalZip}</TableCell>
                                        <TableCell align="left">{row.StateProv}</TableCell>
                                        <TableCell align="left">{row.Country}</TableCell>
                                        <TableCell align="left">{row.ContactName}</TableCell>
                                        <TableCell align="left">{row.ContactPhone}</TableCell>
                                        <TableCell align="left">{row.ContactEmail}</TableCell>
                                        <TableCell align="left">{row.ContactInfo}</TableCell>
                                        <TableCell align="left">{row.Instructions}</TableCell>
                                    </TableRow>
                                ))
                            : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={locationList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <LocationModal openLocationModal={openLocationModal} handleCloseLocationModal={handleCloseLocationModal} />
                <EditLocationModal openEditLocationModal={openEditLocationModal} handleCloseEditLocationModal={handleCloseEditLocationModal} currentLocation={currentLocation} />
            </Paper>
            : <LinearProgress sx={{width: '75vw'}}/>}
            <Alert severity="info" style={classes.alert}>
                <Typography style={classes.alertText}>This is where you can save common Pickup and Delivery locations. You can access these saved locations when requesting or editing a quote. At the top of the Add/Edit Pickup/Delivery boxes, there will be a bar where you can search up the locations you have saved here.</Typography>
            </Alert>
        </Container>
    );
}

export default LocationAddressBook;