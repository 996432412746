import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StockTruck from '../../_resources/stock-truck.png';
import Warehouse from '../../_resources/warehouse.jpg';
import HighwayTruck from '../../_resources/truck-on-highway.jpg';
import PeopleWarehouse from '../../_resources/people-warehouse-clipboard.jpg';
import WarehouseWorkers from '../../_resources/warehouse-workers.jpeg';
import DfqSampleLogo from '../../_resources/DFQ-Sample-Logo.png';
import DfqDevSampleLogo from '../../_resources/DFQ-Dev-Sample-Logo.png';
import Footer from '../../components/Footer';

const classes = {
	firstContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
    loginButton: {
		backgroundColor: '#308cdc',
		marginLeft: 2,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 24
    },
	logo: {
		margin: 15
	},
	firstContainerGrid: {
		minHeight: '60vh',
		paddingTop: '5%'
	},
	firstContainerGridItem: {
		display: 'flex',
		justifyContent: 'center'
	},
	firstContainerHeaderText: {
		color: '#1EBBD7'
	},
	leftAlignment: {
		textAlign: 'left'
	},
	signUpButton: {
		backgroundColor: '#20ac9c',
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 24
    },
	buttonBox: {
		float: 'right',
		margin: 3
	},
	slogan: {
		fontWeight: 'bold',
		fontSize: 18
	},
	secondContainer: {
		paddingTop: 5,
		paddingBottom: 5
	},
	secondContainerHeader: {
		textAlign: 'center',
		fontWeight: 'bold'
	},
	secondContainerGrid: {
		marginTop: 5,
		textAlign: 'center'
	},
	thirdContainer: {
		paddingTop: 5,
		paddingBottom: 5,
		backgroundColor: '#f2f5f7',
	},
	link: {
		color: '#1EBBD7',
	},
	howItWorksGrid: {
		display: 'flex', 
		flexDirection: 'column', 
		justifyContent: 'center'
	},
	howItWorksHeader: {
		fontWeight: 'bold'
	},
	howItWorksGridBox: {
		marginTop: 5,
		paddingLeft: 3,
		borderLeft: '3px solid #58AFC3'
	}
};

function LandingPage() {
	const dispatch = useDispatch();

	// Reset login status
	useEffect(() => { 
		document.title = "DFQ";
		dispatch(userActions.logout()); 
	}, []);

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.firstContainer}>
			<Box sx={classes.buttonBox}>
				<Link to='/signup'><Button variant="contained" sx={classes.signUpButton}>Sign Up</Button></Link>
				<Link to='/login'><Button variant="contained" sx={classes.loginButton}>Log In</Button></Link>
			</Box>
			{process.env.NODE_ENV === 'production' ? <img src={DfqSampleLogo} height={30} alt='logo' style={classes.logo}/> : <img src={DfqDevSampleLogo} height={30} alt='logo' style={classes.logo}/>}
			<Grid
				container
				sx={classes.firstContainerGrid}
			>
				<Grid item xs={12} sm={6} sx={classes.firstContainerGridItem}>
					<Box sx={classes.leftAlignment}>
						<Typography variant='h1' sx={classes.firstContainerHeaderText}>Quoting,</Typography>
						<Typography variant='h1' sx={classes.firstContainerHeaderText}>Simplified.</Typography>
						<Typography variant='h5'>A simple and efficient quoting system </Typography>
						<Typography variant='h5'>so you can focus on what you do best</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} >
					<img src={StockTruck} height={300} alt='stock-truck'/>
				</Grid>
			</Grid>
		</Container>
		<Container sx={classes.secondContainer}>
			<Typography variant='h3' sx={classes.secondContainerHeader}>Your End-To-End Logistics Partner</Typography>
			<Grid container spacing={2} sx={classes.secondContainerGrid}>
				<Grid item xs={12} sm={4} sx={classes.leftAlignment}>
					<img src={Warehouse} height={300} width={300} alt='warehouse'/>
					<Typography variant='h4'>Loads</Typography>
					<Typography>Integrate our digital platform to post loads for vendors to see. We will help you keep track of your pickups, deliveries, shipping costs, and much more. Posting a load can be done in minutes!</Typography>
				</Grid>
				<Grid item xs={12} sm={4} sx={classes.leftAlignment}>
					<img src={HighwayTruck} height={300} width={300} alt='truck'/>
					<Typography variant='h4'>Quotes</Typography>
					<Typography>Our software allows you to send your load for quoting to potentially hundreds of vendors across North America. You get to choose which vendor is the best fit for you!</Typography>
				</Grid>
				<Grid item xs={12} sm={4} sx={classes.leftAlignment}>
					<img src={PeopleWarehouse} height={300} width={300} alt='people'/>
					<Typography variant='h4'>Business</Typography>
					<Typography>Make your job easier, cutting down operating costs, and boosting profits. We help to drive your business forward by utilizing the latest technology and software on the market!</Typography>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth={false} disableGutters sx={classes.thirdContainer}>
			<Container>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<img src={WarehouseWorkers} alt='warehouse-workers'/>
					</Grid>
					<Grid item xs={12} sm={6} sx={classes.howItWorksGrid}>
						<Typography variant='h3' sx={classes.howItWorksHeader}>How it Works</Typography>
						<Box sx={classes.howItWorksGridBox}>
							<Typography variant='h4'>Sign Up</Typography>
							<Typography variant='body'>Register by clicking <Link to='/signup' style={classes.link}>here</Link>. It is free and you will be able to get instant access to quotes from across North America. Additionally, we make collaboration simple and seamless. You can invite teammates to help you along your freight journey without the need for account sharing!</Typography>
						</Box>
						<Box sx={classes.howItWorksGridBox}>
							<Typography variant='h4'>Post a Load</Typography>
							<Typography variant='body'>Fill out the required information and once it is submitted, vendors relevant to you will be notified. We have tools that make this process efficient by allowing users to create templates for their loads. This speeds up the process saving you time and money!</Typography>
						</Box>
						<Box sx={classes.howItWorksGridBox}>
							<Typography variant='h4'>Find the Right Quote</Typography>
							<Typography variant='body'>Award the load to a vendor that best fits your needs. We provide analytical tools to make sure you are getting a competitive price. Once awarded, a purchase order will be automatically generated to further streamline your business operations.</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Container>
		{/* <Footer /> */}
		</>
	);
}

export { LandingPage };