import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../../_actions';
import { useNavigate } from "react-router-dom";
import Header from '../../../components/Header';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import { compareUtil } from '../../../_helpers';
import { formatShipmentType, numberWithCommas } from '../../../_helpers';
import { CSVLink } from "react-csv";
import FilterOptions from '../../../components/FilterOptions';
import FilterListIcon from '@mui/icons-material/FilterList';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { visuallyHidden } from '@mui/utils';
import LinearProgress from '@mui/material/LinearProgress';
import Footer from '../../../components/Footer';

const headCells = [
    { id: 'Id', numeric: true, label: 'Load No.' },
    { id: 'FirstPickupDate', numeric: false, label: 'Pickup Date' },
	{ id: 'LastDeliveryDate', numeric: false, label: 'Delivery Date' },
	{ id: 'ShipmentType', numeric: false, label: 'Shipment Type' },
	{ id: 'FirstPickupLocation', numeric: false, label: 'From' },
	{ id: 'LastDeliveryLocation', numeric: false, label: 'To' },
	{ id: 'LoadStatus', numeric: false, label: 'Status' },
	{ id: 'PalletCount', numeric: false, label: 'Pallets' },
	{ id: 'AwardedTo', numeric: false, label: 'Awarded To' },
	{ id: 'AwardedPrice', numeric: true, label: 'Awarded Price' },
	{ id: 'CreatedOn', numeric: false, label: 'Created Time' },
];

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	cvsLink: {
		float: 'right',
	},
	filterDiv: {
		float: 'right',
		marginRight: 100,
		cursor: 'pointer'
	},
	filterIcon: {
		color: '#1b79ce'
	},
	filterIconRotated: {
		color: '#1b79ce',
		transform: 'rotate(-0.5turn)'
	},
	filterIconLabel: {
		float: 'right',
		paddingLeft: 1
	},
	excelButton:{
		textTransform: 'none',
	},
	contentContainer: {
		paddingTop: 8,
		paddingBottom: 5,
		px: 10
	},
	tableInfo: {
		marginBottom: 1,
	},
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 710
    },
	tableHeaderStyle: {
		fontWeight: 'bold',
	}
};

function CurrentLoadsPage() {
	const [filteredCompanyLoads, setFilteredCompanyLoads] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('FirstPickupDate');
	const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
	const user = useSelector(state => state.authentication.user);
	const loading = useSelector(state => state.load.loading);
	const companyLoads = useSelector(state => state.load.companyLoads);
    const dispatch = useDispatch();
	const navigate = useNavigate();
	const [appliedFilters, setAppliedFilters] = useState([]);

    const filtersCount = appliedFilters.length;

    useEffect(() => { 
        document.title = "DFQ - Current Loads";
		dispatch(loadActions.getCompanyLoads(user.CompanyId, 'current'));
    }, []);

	useEffect(() => { 
		setFilteredCompanyLoads(companyLoads);
    }, [companyLoads]);

	const toggleShowFilters = () => {
		setShowFilters(!showFilters);
	};

	const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

	const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

	const openLoadDetails = load => {
		navigate(`/loads/details/general/${load.Id}`);
	};

	return (
		<>
		<CssBaseline />
        <Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			{!loading ?
            <Container maxWidth={false} disableGutters sx={classes.contentContainer}>
				<div style={{marginBottom: 12}}>
				<CSVLink 
					data={filteredCompanyLoads}
					//headers={headCells}
					filename={"CompanyLoads.csv"}
					style={classes.cvsLink}
				>
					<Button variant="outlined" fontSize="small" sx={classes.excelButton} startIcon={<FolderOpenIcon />}>Save as Excel</Button>
                </CSVLink>
				</div>
				<div style={classes.filterDiv} onClick={() => toggleShowFilters()}>
					{!showFilters ? <FilterListIcon sx={classes.filterIcon}/> : <FilterListIcon sx={classes.filterIconRotated}/>}
					<Typography sx={classes.filterIconLabel}>
						{showFilters ? 'Hide Filters' : 'Filters'}
						{filtersCount > 0 ? `. ${filtersCount} Applied` : ''}
					</Typography>
				</div>
				<Typography sx={classes.tableInfo}>{filteredCompanyLoads.length} Loads</Typography>
				<FilterOptions showFilters={showFilters} setAppliedFilters={setAppliedFilters} setFilteredCompanyLoads={setFilteredCompanyLoads} companyLoads={companyLoads}/>
				<Paper elevation={3}>
					<TableContainer sx={classes.tableStyle}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											align={'left'}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={createSortHandler(headCell.id)}
												sx={classes.tableHeaderStyle}
											>
												{headCell.label}
												{orderBy === headCell.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredCompanyLoads && filteredCompanyLoads.length > 0 ?
								compareUtil.stableSort(filteredCompanyLoads, compareUtil.getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow hover sx={{ cursor: 'pointer' }} key={row.Id} onClick={() => openLoadDetails(row)}>
										<TableCell align="left">{row.Id}</TableCell>
										<TableCell align="left">{dayjs(row.FirstPickupDate).format('MMM DD, YYYY')}</TableCell>
										<TableCell align="left">{dayjs(row.LastDeliveryDate).format('MMM DD, YYYY')}</TableCell>
										<TableCell align="left">{formatShipmentType(row.ShipmentType)}</TableCell>
										<TableCell align="left">{row.FirstPickupLocation}</TableCell>
										<TableCell align="left">{row.LastDeliveryLocation}</TableCell>
										<TableCell align="left">{row.LoadStatus}</TableCell>
										<TableCell align="left">{row.PalletCount}</TableCell>
										<TableCell align="left">{row.AwardedTo}</TableCell>
										<TableCell align="left">{row.AwardedPrice ? '$' + numberWithCommas(row.AwardedPrice) : row.AwardedPrice}</TableCell>
										<TableCell align="left">{dayjs(row.CreatedOn).format('MMM DD, YYYY h:mm A')}</TableCell>
									</TableRow>
								))
								: null}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={filteredCompanyLoads.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</Container>
			: <LinearProgress />}
		</Container>
        {/* <Footer /> */}
		</>
	);
}

export { CurrentLoadsPage };