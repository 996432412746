import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../_actions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const classes = {
	autocompleteStyle: {
		width: '100%',
		backgroundColor: 'white',
        marginTop: 2
	}
};

function PickupTemplateSearch(props){
    const { companyId, setPickupTemplate } = props;
    const loadingTemplateList = useSelector(state => state.load.loadingTemplateList);
    const pickupTemplatesList = useSelector(state => state.load.pickupTemplatesList);
    const dispatch = useDispatch();

    return (
        <Autocomplete 
            disablePortal
            onOpen={() => {
                if(pickupTemplatesList.length === 0){
                    dispatch(loadActions.getPickupTemplatesList(companyId));
                } 
            }}
            isOptionEqualToValue={(option, value) => option.Id === value.Id}
            getOptionLabel={(option) => option.Title}
            options={pickupTemplatesList}
            loading={loadingTemplateList}
            onChange={(event, newValue) => {
                setPickupTemplate(newValue);
            }}
            sx={classes.autocompleteStyle}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    label='Saved Pickups - From Address Book'
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loadingTemplateList ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}

export default PickupTemplateSearch;