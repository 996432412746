import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DfqSampleLogo from '../_resources/DFQ-Sample-Logo.png';
import DfqDevSampleLogo from '../_resources/DFQ-Dev-Sample-Logo.png';

const pages = [
    { Title: 'Request Quote', Url: '/loads/new'}, 
    { Title: 'Current Loads', Url: '/loads/current'}, 
    { Title: 'Past Loads', Url: '/loads/past'}, 
    { Title: 'Admin', Url: '/admin/0'}, 
    { Title: 'Help', Url: '/help'}, 
];

const settings = ['Logout'];

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [companyLogoSrc, setCompanyLogoSrc] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const companyLogo = useSelector(state => state.authentication.companyLogo);

  useEffect(() => { 
    if(!companyLogo){
        dispatch(userActions.getCompanyLogo(user.CompanyId));
    }
  }, []);

  useEffect(() => { 
    if(companyLogo){
      setCompanyLogoSrc(`data:image/${companyLogo.CompanyLogoFormat === 'jpeg' ? 'jpeg' : 'png'};base64,${companyLogo.CompanyLogo}`);
    }
  }, [companyLogo]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getInitials = () => {
      const firstName = user.FirstName || ''; // Default to an empty string if undefined
      const lastName = user.LastName || '';   // Default to an empty string if undefined
      
      const firstInitial = firstName.charAt(0).toUpperCase();
      const lastInitial = lastName.charAt(0).toUpperCase();
    
      return `${firstInitial}${lastInitial}`;
  };

  return (
    <AppBar position="static" sx={{backgroundColor: 'white', color: 'black'}}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Link to='/welcome' style={{textDecoration: 'none', color: 'black', paddingTop: 6}}>
            {process.env.NODE_ENV === 'production' ? <img src={DfqSampleLogo} height={30} alt='logo'/> : <img src={DfqDevSampleLogo} height={30} alt='logo'/>}
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <Link key={page.Title} to={page.Url} style={{textDecoration: 'none', color: 'black'}}>
                    <MenuItem key={page.Title} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page.Title}</Typography>
                    </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', paddingLeft: '5%' } }}>
            {pages.map((page) => (
              <Link key={page.Title} to={page.Url} style={{textDecoration: 'none', color: 'black'}}>
                <Button
                    key={page.Title}
                    //onClick={handleNavMenuColor}
                    sx={{ my: 2, color: page.Url === window.location.pathname ? '#2c88d9' : '#293845', display: 'block', textTransform: 'none', fontSize: 15, marginRight: 2, fontWeight: 'bold' }}
                >
                    {page.Title}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, paddingRight: '5%' }}>
            <IconButton sx={{ paddingRight: 1 }}>
              {companyLogo && companyLogo.CompanyLogoFileName !== 'default.png' ? 
                <Avatar alt={user.CompanyName} sx={{ width: 48, height: 48 }} src={companyLogoSrc} />
              : 
                <Avatar sx={{ width: 48, height: 48 }}>{getInitials()}</Avatar>
              }
            </IconButton>
            <IconButton onClick={handleOpenUserMenu} sx={{ paddingRight: 1, paddingTop: 2, float: 'right' }}>
                <ExpandMoreIcon />
            </IconButton>
            <Box sx={{float: 'right', paddingTop: 1}}>
                <Typography sx={{textAlign: 'center'}}>{user.FirstName + ' ' + user.LastName}</Typography>
                <Typography sx={{fontSize: 14, textAlign: 'center'}}>{user.CompanyName}</Typography>
            </Box>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Link to='/' style={{textDecoration: 'none', color: 'black'}}>{setting}</Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;